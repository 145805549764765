// Navigation dans le site
// -----------------------

.site-navigation {
  .menu {
    a,
    .item-text {
      padding: 0.5rem 1rem;
    }

    a {
      font-size: rem-calc(18);
      transition: color 0.1s ease-out;

      &:hover {
        color: $black;
      }
    }

    .is-active > a {
      color: $black;
      font-weight: $global-weight-bold;
      background-color: transparent;
    }

    .menu {
      background-color: $navigation-submenu-background;

      a {
        padding-left: 2rem;
      }
    }
  }

  @include breakpoint(medium) {
    margin-top: $global-margin;
    padding: $global-padding 0;
    border-right: 2px solid $white;
  }

  // affichage du menu sur mobile
  @include breakpoint(small only) {
    @include xy-grid-frame($vertical: true);
    @include xy-grid(vertical, $wrap: false);
    @include flex-align(center, stretch);
    position: fixed;
    top: -100%;
    width: 100%;
    background-color: $body-background;
    transition: top 0.25s ease-in;

    &.is-active {
      top: 0;
    }

    .menu {
      @include xy-cell-block($vertical: true);

      a {
        display: inline-block;
      }
    }
  }
}
