// Page des témoignages
// --------------------

.temoignage.article {
  position: relative;

  .article-titre {
    font-weight: $global-weight-bold;

    // ajoute des guillemets
    @include breakpoint(medium) {
      margin-left: 2.5rem;
      margin-right: 2.5rem;

      &::before,
      &::after {
        @include icon();
        position: absolute;
        left: auto;
        right: auto;
        color: $medium-gray;
        font-size: 2rem;
      }

      &::before {
        left: 0;
        content: -icon-value('quote-left');
      }

      &::after {
        right: 0;
        content: -icon-value('quote-right');
      }
    }
  }
}
