// Étend le composant Card
// -----------------------

.card-divider.title {
  padding: $card-title-padding;
  color: $white;
  font-size: rem-calc(18);

  &:hover {
    background: scale-color($card-divider-background, $lightness: 10%);
    color: $light-gray;
  }

  @include breakpoint(medium) {
    font-size: rem-calc(22);
  }
}
