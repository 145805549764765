// Définition des polices utilisées
// --------------------------------

////
/// Glacial Indifference
/// OFL - Alfredo Marco Pradil
////

$glacial-font-path: '#{$font-path}/glacial-indifference';

@font-face {
  font-family: 'Glacial Indifference';
  src: local('Glacial Indifference Regular'),
       url('#{$glacial-font-path}/glacial-regular.woff2') format('woff2'),
       url('#{$glacial-font-path}/glacial-regular.woff') format('woff');
  font-weight: $global-weight-normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: local('Glacial Indifference Italic'),
       url('#{$glacial-font-path}/glacial-italic.woff2') format('woff2'),
       url('#{$glacial-font-path}/glacial-italic.woff') format('woff');
  font-weight: $global-weight-normal;
  font-style: italic;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: local('Glacial Indifference Bold'),
       url('#{$glacial-font-path}/glacial-bold.woff2') format('woff2'),
       url('#{$glacial-font-path}/glacial-bold.woff') format('woff');
  font-weight: $global-weight-bold;
  font-style: normal;
}
