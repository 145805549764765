// Composants définis par SPIP
// ---------------------------

/// Pagination

.pagination {
  margin-bottom: $pagination-margin-bottom;

  a,
  .on {
    display: inline-block;
    padding: $pagination-item-padding;
    font-weight: $global-weight-normal;
  }

  a {
    border-radius: $pagination-radius;
    color: $pagination-item-color;

    &:hover {
      background: $pagination-item-background-hover;
      color: $pagination-item-color;
    }
  }

  .on {
    background: $pagination-item-background-current;
    color: $pagination-item-color-current;
    cursor: default;
  }

  .sep {
    visibility: hidden;
    display: inline-block;
    width: rem-calc(10);
  }
}

/// Vignettes et documents

.hasbox {
  @extend .thumbnail;

  .spip_documents > &,
  .spip_documents > dt > & {
    margin-bottom: 0;
  }
}

@include breakpoint(small only) {
  .spip_documents_left,
  .spip_documents_right {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: none !important;
  }
}
