// Structure et classes de base des pages
// --------------------------------------

html,
body {
  height: 100%;
}

iframe {
  margin-bottom: $thumbnail-margin-bottom;
}

@mixin compact() {
  margin-bottom: 0;

  @at-root {
    hr#{&} {
      margin: 0.5rem auto;
    }
  }
}

.compact {
  @include compact();
}

@include -zf-each-breakpoint() {
  .#{$-zf-size}-compact {
    @include compact();
  }
}

.full-height {
  min-height: 100%;
}

/// Conteneurs principaux -----------------------------------------------------

.site-wrapper {
  @include xy-grid();
}

.site-sidebar,
.site-content {
  @include xy-cell-base(full);
}

@include breakpoint(medium) {
  .site-wrapper {
    @include xy-grid-frame(true);
  }

  .site-sidebar {
    @include xy-cell-base(shrink);
    @include xy-cell-block(true);
    min-width: 215px;
  }

  .site-content {
    @include xy-cell-base(auto);
    @include xy-cell-block(true);
  }
}

/// Contenu -------------------------------------------------------------------

.site-content {
  @include xy-grid(vertical, $wrap: false);
  @include flex-align($y: middle);
}

.site-branding,
.site-footer {
  @include xy-cell-base(shrink);
}

.page-wrapper {
  @include xy-cell-base(auto);
  @include xy-gutters($gutter-type: padding);
  position: relative;
  width: 100%;
  max-width: $global-width;
}

/// En-tête

.site-branding {
  margin-bottom: $global-margin;

  @include breakpoint(small only) {
    @include flex-align-self(stretch);
    padding: 0.25rem 0.5rem;
    background-color: $primary-color;
  }

  @include breakpoint(medium) {
    margin: $global-margin;
  }
}

.menu-toggler {
  color: $white;
  cursor: pointer;
}

.site-title {
  margin: 0;
  line-height: 1;
  font-weight: $global-weight-bold;

  @include breakpoint(small only) {
    display: inline-block;
    margin-left: 0.25rem;
  }
}

.site-subtitle {
  display: none;
}

@include breakpoint(medium) {
  .site-title {
    margin-bottom: 0.5rem;
    font-size: rem-calc(52);
    text-align: center;
  }

  .site-subtitle {
    display: block;
    margin: 0;
    padding-top: 0.5rem;
    border-top: 1px solid;
    color: $primary-color;
    line-height: 1;
    text-align: center;
  }
}

/// Contenu de la page

.page-title {
  @include xy-gutters($gutter-type: padding, $negative: true);
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  background-color: $page-title-background;
  color: $primary-color;
  font-size: rem-calc(24);
  font-weight: $global-weight-bold;

  @include breakpoint(medium) {
    padding: 0.25rem 1rem;
  }

  > span {
    color: $dark-gray;
    font-style: italic;
    font-weight: $global-weight-normal;
  }
}

/// Pieds de page

.site-footer {
  padding: 0 0.5rem 0.25rem 0.5rem;
  color: $dark-gray;
  font-size: 90%;
  text-align: center;
}

.site-copy {
  margin: 0;
}
