// Formulaires et champs
// ---------------------

label {
  cursor: pointer;
}

textarea {
  resize: vertical;
}

/// Indique qu'un champ d'un formulaire est requis.
.asterisk {
  margin-left: rem-calc(2);
  color: $alert-color;
  font-size: 90%;
}

/// Prends en compte `.compact` pour les erreurs
.compact + .form-error {
  margin-top: 0.25rem;
  margin-bottom: 0;
}

@include -zf-each-breakpoint() {
  .#{$-zf-size}-compact + .form-error {
    margin-top: 0.25rem;
    margin-bottom: 0;
  }
}
