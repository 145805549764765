// Icônes de l'application
// -----------------------
// @link https://icomoon.io/

/// Variables et fonctions

// nom => valeur
$icons-vars: (
  'check': "\f00c",
  'close': "\f00d",
  'navicon': "\f0c9",
  'angle-left': "\f104",
  'angle-right': "\f105",
  'quote-left': "\f10d",
  'quote-right': "\f10e",
);

/// Retourne le code d'une icône.
///
/// @param {String} $name - Le nom de l'icône.
@function -icon-value($name) {
  @return map-get($icons-vars, $name);
}

/// Police

$icons-font-path: '#{$font-path}/icons' !default;
$icons-font-family: 'rmaurer-icons' !default;

@font-face {
  font-family: $icons-font-family;
  src: url('#{$icons-font-path}/#{$icons-font-family}.ttf') format('truetype'),
       url('#{$icons-font-path}/#{$icons-font-family}.woff') format('woff'),
       url('#{$icons-font-path}/#{$icons-font-family}.svg##{$icons-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

/// Mixins

/// Définis les styles nécessaire pour utiliser la police, pour
/// éventuellement une icône donnée.
@mixin icon($name: null) {
  font-family: $icons-font-family !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  speak: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @if $name {
    content: -icon-value($name);
  }
}

/// Classes

.icon {
  @include icon();
  display: inline-block;
}

@each $name, $code in $icons-vars {
  .icon-#{$name} {
    &::before {
      content: $code;
    }
  }
}
