// Paramètres de Foundation et de l'application
// --------------------------------------------

/// Foundation

@import 'util/util';

// 1. Global
// ---------

$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$light-gray: #eeeeee;
$medium-gray: #bdbdbd;
$dark-gray: #757575;
$foundation-palette: (
  primary: #837261,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
  light-gray: $light-gray,
  medium-gray: $medium-gray,
  dark-gray: $dark-gray
);
$black: #0a0a0a;
$white: #fefefe;
$body-background: #d4d4d4;
$body-font-color: $black;
$body-font-family: 'Glacial Indifference', Helvetica, Roboto, Arial, sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-flexbox: true;

@include add-foundation-colors;

// 4. Base Typography
// ------------------

$header-small-font-color: #9e9e9e;

// 14. Card
// --------

$card-background: none;
$card-font-color: $body-font-color;
$card-divider-background: $secondary-color;
$card-border: 1px solid $secondary-color;
$card-padding: $global-padding;
$card-title-padding: 0.25rem 0.5rem;

// 22. Media Object
// ----------------

$mediaobject-margin-bottom: $global-margin / 2;

/// Application

// 1. Paths
// --------

$font-path: '../fonts';
$img-path: '../img';

// 2. Base & navigation
// --------------------

$page-title-background: scale-color($body-background, $lightness: -5%);
$navigation-submenu-background: $page-title-background;
