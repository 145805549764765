// Classes utiles à plusieurs pages
// --------------------------------

/// Mets en forme le conteneur du lien « Lire la suite » en le centrant
/// et en dessinant un trait horizontal de chaque côté.
.read-more {
  @include xy-grid($wrap: false);
  margin-bottom: $global-margin;
  font-style: italic;

  &::before,
  &::after {
    @include xy-cell-base(auto);
    @include flex-align-self(middle);
    display: inline-block;
    height: 1px;
    margin: 0 1rem;
    border-bottom: 1px solid $medium-gray;
    content: '';
  }
}

/// Article

.article-titre {
  > span {
    color: $dark-gray;
    font-style: italic;
    font-weight: $global-weight-normal;
  }
}

.article-intro {
  margin-bottom: $global-margin / 2;

  > :last-child {
    margin-bottom: 0;
  }
}
